const approvalTask = {
  state: {
    pageType: '',
    beginUpdate: new Date(),
    detailId: null,
    companyId: null,
    publisherId: null,
    userId: null,
    summaryType: '',
    taskCount: null,
    taskType: null,
    pageNumber: 1,
    keyword: ''
  },
  mutations: {
    set_approvalTask_pageType (state, data) {
      state.pageType = data
    },
    set_approvalTask_update (state, data) {
      state.beginUpdate = data
    },
    set_approvalTask_detailId (state, data) {
      state.detailId = data
    },
    set_approvalTask_companyId (state, data) {
      state.companyId = data
    },
    set_approvalTask_publisherId (state, data) {
      state.publisherId = data
    },
    set_approvalTask_userId (state, data) {
      state.userId = data
    },
    set_approvalTask_taskCount (state, data) {
      state.taskCount = data
    },
    set_approvalTask_summaryType (state, data) {
      state.summaryType = data
    },
    set_approvalTask_taskType (state, data) {
      state.taskType = data
    },
    set_approvalTask_pageNumber (state, data) {
      state.pageNumber = data
    },
    set_approvalTask_keyword (state, data) {
      state.keyword = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default approvalTask
