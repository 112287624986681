<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" ></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent" ></component>
      </div>
      <div
        slot="bottom_box_content"
        style="padding:10px 10px;"
      >
        <component
          :is="curBottomComponent"
        ></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import taskStoreModule from '@/store/modules/approvalTask'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    ListComponent: () => import('@/components/approvalTask/TaskSummary'),
    DetailComponent: () => import('@/components/approvalTask/ApprovalFrame'),

    Right: () => import('@/components/approvalTask/Summary'),
    Left: () => null
  },
  data () {
    return {
    }
  },
  created () {
    if (!this.$store.hasModule('approvalTask')) {
      this.$store.registerModule('approvalTask', taskStoreModule)
      this.$store.commit('set_approvalTask_companyId', this.$store.getters.token.userInfo.companyId)
      this.$store.commit('set_approvalTask_publisherId', this.$store.getters.token.userInfo.publisherId)
      this.$store.commit('set_approvalTask_userId', this.$store.getters.token.userInfo.userId)
      this.$store.commit('set_approvalTask_pageType', 'list')
    }
  },
  methods: {
    initTagEvent () {
    // 注册头部事件
      this.setLinkRouterMaps(['taskList', () => {
        this.$store.commit('set_approvalTask_pageType', 'list')
      }])
    },

    initComponents () {
      // 清空便签项
      switch (this.pageType) {
        case 'list':
          this.setLeftComponent(null)
          this.setRightComponent('Right')
          this.setShowLeftBlock(false)
          this.setShowRightBlock(true)
          this.setShowMap(false)
          this.setBottomHeight('90%')
          this.setBottomComponent('ListComponent')
          this.setLinkTagArray({ key: 'taskList', value: '审批任务' })
          this.setActivedTagName('taskList')
          break
        case 'detail':
          this.setLeftComponent(null)
          this.setRightComponent(null)
          this.setShowLeftBlock(false)
          this.setShowRightBlock(false)
          this.setShowMap(false)
          this.setBottomHeight('90%')
          this.setBottomComponent('DetailComponent')
          this.setLinkTagArray({ key: 'taskList', value: '审批任务' })
          this.setLinkTagArray({ key: 'taskDetail', value: '任务详情' })
          this.setActivedTagName('taskDetail')
          break
        default:
          break
      }
    }
  },
  watch: {
    pageType () {
      this.initComponents()

      this.initTagEvent()
    }
  },
  computed: {
    pageType () {
      return this.$store.state.approvalTask ? this.$store.state.approvalTask.pageType : ''
    }
  },
  destroyed () {
    // 卸载reconciliation动态store模块
    this.$store.unregisterModule('approvalTask')
    this.setShowLeftBlock(false)
    this.setShowMap(false)
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
